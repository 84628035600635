import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useMemo, useState } from "react";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { moneyFormat, unitFormat } from "utils/money-format";
import { neutral, primary } from "style/color";
import { useCreate } from "@refinedev/core";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ticker: string;
  reinvest: boolean;
  amount: number;
  type: string;
  nbReinvestedUnits?: number;
  data: {
    idFund: number;
    name: string;
    reference: any;
    units: number;
    ratio: number;
  }[];
  distributionDate: string;
  referenceDate: string;
}

export const DividendConfirmationModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  ticker,
  reinvest,
  amount,
  type,
  data,
  distributionDate,
  referenceDate,
  nbReinvestedUnits,
}) => {
  const [distributionData, setDistributionData] = useState<
    {
      idFund: number;
      name: string;
      reference: string;
      units: number;
      ratio: number;
      value: number;
      nbReinvestedUnits: number;
    }[]
  >([]);
  const [reinvestTotalError, setReinvestTotalError] = useState({
    error: false,
    message: "",
  });
  const [error, setError] = useState({ error: false, message: "" });

  const { mutate, isLoading } = useCreate();

  const navigate = useNavigate();
  useEffect(() => {
    if (data && data?.length > 0) {
      const updatedData = data.map((fund) => {
        const roundedAmount =
          type === "UNIT"
            ? Math.round((fund.ratio * amount + Number.EPSILON) * 1000) / 1000
            : Math.round((fund.ratio * amount + Number.EPSILON) * 100) / 100;
        const roundedNbReinvest =
          nbReinvestedUnits && nbReinvestedUnits !== 0
            ? Math.round(
                (fund.ratio * nbReinvestedUnits + Number.EPSILON) * 1000
              ) / 1000
            : 0;

        return {
          idFund: fund.idFund,
          name: fund.name,
          reference: fund.reference,
          units: fund.units,
          ratio: fund.ratio,
          value: roundedAmount,
          nbReinvestedUnits: roundedNbReinvest,
        };
      });
      setDistributionData(updatedData);
    }
  }, [amount, data, nbReinvestedUnits, type]);

  const sumAmount = useMemo(() => {
    const total = distributionData.reduce((prev, next) => prev + next.value, 0);
    const rounded =
      type === "UNIT"
        ? Math.round((total + Number.EPSILON) * 1000) / 1000
        : Math.round((total + Number.EPSILON) * 100) / 100;
    return rounded;
  }, [distributionData, type]);

  const sumReinvestedUnits = useMemo(() => {
    const total = distributionData.reduce(
      (prev, next) => prev + next.nbReinvestedUnits,
      0
    );
    const rounded = Math.round((total + Number.EPSILON) * 1000) / 1000;

    return rounded;
  }, [distributionData]);
  const diffAmount = useMemo(
    () =>
      type === "UNIT"
        ? Math.round((amount - sumAmount + Number.EPSILON) * 1000) / 1000
        : Math.round((amount - sumAmount + Number.EPSILON) * 100) / 100,
    [amount, sumAmount, type]
  );

  const diffReinvestedUnits = useMemo(
    () =>
      nbReinvestedUnits
        ? Math.round(
            (nbReinvestedUnits - sumReinvestedUnits + Number.EPSILON) * 1000
          ) / 1000
        : 0,
    [nbReinvestedUnits, sumReinvestedUnits]
  );

  const updateValue = (id: number, newValue: number, reinvest: boolean) => {
    const items = [...distributionData];
    const fund = distributionData.find((fund) => fund.idFund === id);
    if (reinvest) {
      const roundValue = Math.round((newValue + Number.EPSILON) * 1000) / 1000;
      if (fund) {
        fund.nbReinvestedUnits = roundValue;
      }
    } else {
      const roundValue =
        type === "UNIT"
          ? Math.round((newValue + Number.EPSILON) * 1000) / 1000
          : Math.round((newValue + Number.EPSILON) * 100) / 100;
      if (fund) {
        fund.value = roundValue;
      }
    }
    setDistributionData(items);
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle color="primary">
        Add {type.toLocaleLowerCase()} dividend for investment {ticker}{" "}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography pb={1}>
          Are you sure you want to distribute the{" "}
          <b>
            {" "}
            {`${amount}`} {type === "CASH" ? "dollars" : "units"}
          </b>{" "}
          across these funds{" "}
          <b> {reinvest && `and reinvest the ${nbReinvestedUnits} units`}</b> as
          follow?
        </Typography>
        <Grid item xs={12}>
          {distributionData.length > 0
            ? distributionData.map((fund, index) => (
                <Grid
                  key={index}
                  sx={{ backgroundColor: "#f7f3f1", p: 1, my: 1 }}
                  alignContent={"center"}
                >
                  <Typography color={primary[500]}>{`${index + 1}- ${
                    fund.name
                  } ${
                    fund.reference ? `(Ref:${fund.reference})` : ""
                  }`}</Typography>
                  <Grid container rowSpacing={1} columnGap={0.5}>
                    <Grid item sm={3}>
                      <TextField
                        label={"Units"}
                        value={unitFormat(fund.units) || 0}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <TextField
                        label={"Ratio"}
                        value={fund.ratio || 0}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                        disabled
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <TextField
                        label={`Distributed ${
                          type === "CASH" ? "amount" : "units"
                        }`}
                        value={
                          type === "CASH"
                            ? moneyFormat(fund.value)
                            : fund.value || 0
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                        onChange={(event) => {
                          updateValue(
                            fund.idFund,
                            parseFloat(event.target.value),
                            false
                          );
                          setError({ error: false, message: "" });
                        }}
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <TextField
                        label={"Units to buy"}
                        value={fund.nbReinvestedUnits || 0}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                        onChange={(event) => {
                          updateValue(
                            fund.idFund,
                            parseFloat(event.target.value),
                            true
                          );
                          setReinvestTotalError({ error: false, message: "" });
                        }}
                        disabled={
                          nbReinvestedUnits === undefined ||
                          nbReinvestedUnits === 0
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : `No funds.`}
          <>
            <Grid>
              <Grid container rowSpacing={1} columnGap={0.5}>
                <Grid item sm={5} alignContent={"center"}>
                  <Typography color={primary[500]}>Total Entered</Typography>
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label={`Total ${type === "CASH" ? "amount" : "units"}`}
                    value={sumAmount}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label={"Total units to buy"}
                    value={sumReinvestedUnits}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container rowSpacing={1} columnGap={0.5}>
                <Grid item sm={5} alignContent={"center"}>
                  <Typography color={primary[500]}>Target</Typography>
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label={`Target ${type === "CASH" ? "amount" : "units"}`}
                    value={amount}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label={"Target units to buy"}
                    value={nbReinvestedUnits ? nbReinvestedUnits : 0}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container rowSpacing={1} columnGap={0.5}>
                <Grid item sm={5} alignContent={"center"}>
                  <Typography color={primary[500]}>Delta</Typography>
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label={`Difference`}
                    value={diffAmount}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label={"Difference"}
                    value={diffReinvestedUnits}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
        {error.error && (
          <Typography pb={1} sx={{ color: "red" }} variant="bodyCopyRegular">
            {error.message}
          </Typography>
        )}
        <br></br>
        {reinvestTotalError.error && (
          <Typography pb={1} sx={{ color: "red" }} variant="bodyCopyRegular">
            {reinvestTotalError.message}
          </Typography>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Typography
            pb={1}
            sx={{ color: neutral[600] }}
            variant="bodyCopyRegular"
          >
            Note: Distributed units are rounded to <b>3 decimal</b>, while cash
            are rounded to <b>2 decimal</b>.
          </Typography>

          <Button
            variant="contained"
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              const roundedSumAmount =
                type === "UNIT"
                  ? Math.round((sumAmount + Number.EPSILON) * 1000) / 1000
                  : Math.round((sumAmount + Number.EPSILON) * 100) / 100;

              const roundedSumReinvestedUnits =
                Math.round((sumReinvestedUnits + Number.EPSILON) * 1000) / 1000;

              if (roundedSumAmount !== amount) {
                setError({
                  error: true,
                  message: `The total distributed ${
                    type === "CASH" ? `amount` : `units`
                  } must equal ${amount}`,
                });
              }
              if (
                nbReinvestedUnits &&
                roundedSumReinvestedUnits !== nbReinvestedUnits
              ) {
                setReinvestTotalError({
                  error: true,
                  message: `The total reinvested units must equal ${nbReinvestedUnits}`,
                });
              }
              if (
                roundedSumAmount === amount &&
                nbReinvestedUnits === roundedSumReinvestedUnits
              ) {
                mutate(
                  {
                    resource: "fund-dividend-distribution",
                    values: {
                      type: type,
                      amount: amount,
                      ticker: ticker,
                      reinvest: reinvest,
                      distributionDate: distributionDate,
                      referenceDate: referenceDate,
                      nbReinvestedUnits: nbReinvestedUnits,
                      distributions: distributionData,
                    },
                  },
                  {
                    onSuccess: (data) => {
                      navigate("/investments/list");
                    },
                  }
                );

                setReinvestTotalError({ error: false, message: "" });
                setError({ error: false, message: "" });
                setOpen(false);
              }
            }}
            disabled={error.error}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              setReinvestTotalError({ error: false, message: "" });
              setError({ error: false, message: "" });
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
