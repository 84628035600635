import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral } from "style/color";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";

import { useCustom } from "@refinedev/core";

import { useNavigate } from "react-router-dom";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import { InView } from "react-intersection-observer";
import {
  ITransferTransaction,
  TRANSFER_TRANSACTION_TYPE_ENUM,
} from "interfaces/transfer-transaction";

export const TransferList: React.FC = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit] = useState(2);
  const [transferData, setTransferData] = useState<ITransferTransaction[]>([]);
  const {
    data: paginatedTransfersData,
    isSuccess: isTransferSuccess,
    isFetching: isTransferLoading,
  } = useCustom({
    url: `transfer-transactions`,
    method: "get",
    config: {
      query: {
        limit: limit,
        offset: offset,
        status: "PENDING",
      },
    },
  });

  useEffect(() => {
    if (paginatedTransfersData) {
      if (
        (
          paginatedTransfersData.data as PaginatedEntityList<ITransferTransaction>
        )?.pagination?.offset === 0
      ) {
        setTransferData(
          (
            paginatedTransfersData.data as PaginatedEntityList<ITransferTransaction>
          ).data
        );
      } else {
        setTransferData([
          ...transferData,
          ...(
            paginatedTransfersData.data as PaginatedEntityList<ITransferTransaction>
          ).data,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedTransfersData]);

  const fetchData = (inView: boolean) => {
    if (inView && paginatedTransfersData?.data?.pagination.nextOffset)
      setOffset(paginatedTransfersData?.data?.pagination.nextOffset);
  };

  return (
    <>
      <Box marginTop={4}>
        {isTransferSuccess && (
          <>
            <Grid container>
              <List
                className="successor"
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",

                  [` & .${listItemClasses.root}:hover`]: {
                    backgroundColor: warm[200],
                    cursor: "pointer",
                  },
                }}
              >
                {transferData &&
                  transferData.length > 0 &&
                  transferData.map(
                    //TODO: Refactor in a seperate card
                    (transfer: ITransferTransaction, index: number) => {
                      return (
                        <>
                          <ListItem
                            key={index}
                            onClick={() =>
                              navigate(`/transfer-transactions/status/pending`)
                            }
                          >
                            <Grid
                              alignItems="center"
                              container
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                px: 2,
                              }}
                            >
                              <Grid item xs={12} sm={2} textAlign={"start"}>
                                <ListItemText
                                  sx={{
                                    display: "inline-grid",
                                    width: "auto",
                                    flex: "none",
                                  }}
                                  primary={
                                    <Typography
                                      sx={{ color: `neutral.main` }}
                                      variant="bodyCopyBold"
                                      gutterBottom
                                    >
                                      {`By ${transfer.user.firstName} ${transfer.user.lastName}`}
                                    </Typography>
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={2} textAlign={"start"}>
                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{
                                    color: neutral[900],
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {transfer.transferType}

                                  {transfer.transferType !==
                                    TRANSFER_TRANSACTION_TYPE_ENUM.CASH &&
                                    `  (${transfer.reference})`}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={2} textAlign={"start"}>
                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{ color: neutral[600] }}
                                >
                                  {shortFormat(transfer.createdAt)}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={2} textAlign={"start"}>
                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{
                                    color: neutral[900],
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {`From: ${transfer.senderFund.fundAccountName}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={2} textAlign={"start"}>
                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{
                                    color: neutral[900],
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  {`To: ${transfer.receiverFund.fundAccountName}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={1} textAlign={"end"}>
                                <Typography
                                  sx={{
                                    px: 1,
                                    py: 1,

                                    backgroundColor:
                                      // movement.type ===
                                      // FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION
                                      //   ? primary[50]
                                      //   :
                                      "transparent",
                                  }}
                                  variant="bodyCopyBold"
                                  gutterBottom
                                >
                                  <>${moneyFormat(transfer.amount)}</>
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>

                          <Divider key={index} />
                        </>
                      );
                    }
                  )}
              </List>
            </Grid>
          </>
        )}

        {isTransferLoading ? (
          <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
            {" "}
            <CircularProgress color="primary" />
          </Grid>
        ) : paginatedTransfersData?.data?.pagination.hasNext ? (
          <InView as="div" onChange={(inView) => fetchData(inView)}></InView>
        ) : null}
        {!isTransferLoading && transferData.length === 0 && (
          <Typography variant="subtitle3">No requests found.</Typography>
        )}
      </Box>
    </>
  );
};
