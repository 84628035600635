import {
  IResourceComponentsProps,
  useCreate,
  useCustom,
} from "@refinedev/core";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { Show } from "@refinedev/mui";
import { useNavigate } from "react-router-dom";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { moneyFormat } from "utils/money-format";
import { primary } from "style/color";
import { getMonthName } from "utils/getMonth";

export const BatchFeeUpdate: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offset, setOffset] = useState(0);
  const [inputs, setInputs] = useState<
    {
      fundAccountId: number;
      amount: number;
    }[]
  >([]);

  const [monthAndYear, setMonthAndYear] = useState({
    value: new Date().toISOString().substring(0, 12),
    error: false,
  });

  const [lastMonth, setLastMonth] = useState("");
  const [executionDate, setExecutionDate] = useState({
    value: "",
    error: false,
  });
  const [limit] = useState(30);
  const {
    data: lastMonthFeeData,
    isSuccess: isLastMonthFeeDataSuccess,
    isFetching: isLastMonthFeeDataFetching,
  } = useCustom({
    url: `fund-fees/last-month-fee-added`,
    method: "get",
  });

  const {
    data: fees,
    isSuccess,
    isFetching,
  } = useCustom({
    url: `fund-snapshot/monthly`,
    method: "get",
    config: {
      query: {
        limit: limit,
        offset: offset,
        "filter[month]": parseInt(monthAndYear.value.substring(5)),
      },
    },
    queryOptions: {
      enabled: !!monthAndYear.value,
    },
  });
  const { mutate, isLoading } = useCreate();
  const [error, setError] = useState(false);

  useEffect(() => {
    const date = new Date().toISOString().substring(0, 10);
    setExecutionDate({ value: date, error: false });
    if (!isLastMonthFeeDataFetching && isLastMonthFeeDataSuccess) {
      const nextMonthDate = new Date(
        `${lastMonthFeeData?.data[0].year}-${lastMonthFeeData?.data[0].month}-27`
      );

      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
      const newMonth = new Date(nextMonthDate)
        .toISOString()
        .substring(0, 10)
        .substring(0, 7);

      setLastMonth(newMonth);
      setMonthAndYear({
        value: newMonth,
        error: false,
      });
    }
  }, [
    isLastMonthFeeDataFetching,
    isLastMonthFeeDataSuccess,
    lastMonthFeeData?.data,
  ]);

  useEffect(() => {
    let array: {
      fundAccountId: number;
      amount: number;
    }[] = [];
    if (!isFetching && isSuccess) {
      fees?.data.data &&
        fees?.data.data.length > 0 &&
        fees?.data.data.map((fee: any) =>
          array.push({ fundAccountId: fee.fundId, amount: fee.monthlyTotalFee })
        );
      setInputs(array);
    }
  }, [fees?.data.data, isFetching, isSuccess, monthAndYear]);

  const total = useMemo(() => {
    const totalMonthlyAum = fees?.data.data.reduce(
      (prev: number, next: any) => prev + next.monthlyAumFee,
      0
    );
    const totalMonthlyGrantvestmentFee = fees?.data.data.reduce(
      (prev: number, next: any) => prev + next.monthlyGrantvestmentFee,
      0
    );
    const monthlyTotalFee = fees?.data.data.reduce(
      (prev: number, next: any) => prev + next.monthlyTotalFee,
      0
    );

    const totalNewFee = inputs.reduce((prev: number, next: any) => {
      return prev + next.amount;
    }, 0);

    return {
      totalMonthlyAum: totalMonthlyAum,
      totalMonthlyGrantvestmentFee: totalMonthlyGrantvestmentFee,
      monthlyTotalFee: monthlyTotalFee,
      totalNewFee: totalNewFee,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fees?.data.data, inputs, monthAndYear]);

  const handleInputChange = (index: number, fundId: number, value: number) => {
    setInputs((prev) => {
      const updatedInputs = [...prev];

      updatedInputs[index] = { fundAccountId: fundId, amount: value };

      return updatedInputs;
    });
  };

  return (
    <Show title={`Add monthly Fee`}>
      {isLastMonthFeeDataFetching ? (
        <CircularProgress />
      ) : (
        <Box>
          {isLastMonthFeeDataSuccess && lastMonthFeeData.data && (
            <>
              <Alert severity="info">
                <Typography variant="bodyCopyRegular" marginY={3} paddingX={1}>
                  Last fee was added in{" "}
                  {getMonthName(lastMonthFeeData.data[0].month)}{" "}
                  {lastMonthFeeData.data[0].year}
                </Typography>
              </Alert>
            </>
          )}
          {isLastMonthFeeDataSuccess && (
            <>
              <TextField
                id="feeMonth"
                name="feeMonth"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="month"
                inputProps={{
                  min: lastMonth,
                }}
                value={monthAndYear.value}
                label="Choose Month and year"
                onChange={(event) => {
                  setMonthAndYear({
                    value: event.target.value,
                    error: false,
                  });
                }}
              />
              <TextField
                id="executionDate"
                name="executionDate"
                margin="normal"
                fullWidth
                value={executionDate.value}
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Execution Date"
                onChange={(event) => {
                  setExecutionDate({
                    value: event.target.value,
                    error: false,
                  });
                }}
              />

              <TableContainer component={Paper} sx={{ marginY: 4 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: primary[100] }}>
                      {" "}
                      <TableCell>Fund ID</TableCell>
                      <TableCell>Fund Reference</TableCell>
                      <TableCell>Fund Name</TableCell>
                      <TableCell>Monthly Aum Fee</TableCell>
                      <TableCell>Monthly Grantvestment Fee</TableCell>
                      <TableCell>Monthly Total Fee</TableCell>
                      <TableCell>Total inputs</TableCell>
                    </TableRow>
                  </TableHead>
                  {isFetching ? (
                    <CircularProgress color="primary" sx={{ margin: 3 }} />
                  ) : (
                    <TableBody>
                      <TableRow sx={{ backgroundColor: primary[50] }}>
                        {" "}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {moneyFormat(total.totalMonthlyAum)}
                        </TableCell>
                        <TableCell>
                          {moneyFormat(total.totalMonthlyGrantvestmentFee)}
                        </TableCell>
                        <TableCell>
                          {moneyFormat(total.monthlyTotalFee)}
                        </TableCell>
                        <TableCell> {moneyFormat(total.totalNewFee)}</TableCell>
                      </TableRow>
                      {fees?.data.data && fees?.data.data.length > 0 ? (
                        fees?.data.data.map((data: any, index: number) => (
                          <TableRow key={`row-${index}`}>
                            <TableCell sx={{ paddingY: 0 }}>
                              {data?.fundId}
                            </TableCell>
                            <TableCell sx={{ paddingY: 0 }}>
                              {data?.fundReference}
                            </TableCell>
                            <TableCell sx={{ paddingY: 0 }}>
                              {data?.fundName}
                            </TableCell>
                            <TableCell sx={{ paddingY: 0 }}>
                              {moneyFormat(data?.monthlyAumFee)}
                            </TableCell>
                            <TableCell sx={{ paddingY: 0 }}>
                              {moneyFormat(data?.monthlyGrantvestmentFee)}
                            </TableCell>
                            <TableCell sx={{ paddingY: 0 }}>
                              {moneyFormat(data?.monthlyTotalFee)}
                            </TableCell>
                            <TableCell sx={{ paddingY: 0 }}>
                              <TextField
                                defaultValue={moneyFormat(
                                  data?.monthlyTotalFee
                                )}
                                error={
                                  (inputs.length > 0 &&
                                    isNaN(
                                      inputs.filter(
                                        (elem) =>
                                          elem.fundAccountId === data.fundId
                                      )[0].amount
                                    )) ||
                                  (inputs.length > 0 &&
                                    inputs.filter(
                                      (elem) =>
                                        elem.fundAccountId === data.fundId
                                    )[0].amount < 0)
                                }
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                                onChange={(e) => {
                                  setError(false);
                                  handleInputChange(
                                    index,
                                    data?.fundId,
                                    parseFloat(e.target.value)
                                  );
                                }}
                                label="New Fee"
                                name="fee"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Typography variant="smallCopy" padding={2}>
                          No snapshots found.
                        </Typography>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {error && (
                <Alert severity="error">
                  Please ensure all inputs are filled and contain positive
                  values
                </Alert>
              )}
              <Box sx={{ my: 4, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{ mr: 2 }}
                  startIcon={
                    isLoading && <CircularProgress size={20} color="inherit" />
                  }
                  disabled={isFetching || inputs.length === 0 || error}
                  variant="contained"
                  onClick={() => {
                    const verif = inputs.filter(
                      (elem) => isNaN(elem.amount) || elem.amount < 0
                    );
                    if (verif.length > 0) {
                      setError(true);
                    } else {
                      mutate(
                        {
                          resource: "fund-fees/batch-create",
                          values: {
                            type: "CASH",
                            fundAccountsFee: inputs,
                            referenceDate: executionDate.value,
                            month: monthAndYear.value.substring(5),
                            year: monthAndYear.value.substring(0, 4),
                          },
                          successNotification: () => {
                            return {
                              message: "Fee was add successfully",
                              type: "success",
                            };
                          },
                          errorNotification: (error) => {
                            if (
                              error?.message &&
                              error?.message.includes("already have been added")
                            ) {
                              return {
                                message:
                                  "The fee for the chosen month has already been added.",
                                type: "error",
                              };
                            } else
                              return {
                                message: error?.message as string,
                                type: "error",
                              };
                          },
                        },

                        {
                          onSuccess: () => {
                            navigate("/fund-accounts/list");
                          },
                        }
                      );
                    }
                  }}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate("/fund-accounts/list");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Show>
  );
};
