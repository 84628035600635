import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { moneyFormat } from "utils/money-format";
import { useCustom } from "@refinedev/core";
import { useNavigate, useParams } from "react-router-dom";
import { primary } from "style/color";
import { Show } from "@refinedev/mui";
import { DividendConfirmationModal } from "components/modal/dividend-modal-confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface ModalProps { }

export const DividendForm: React.FC<ModalProps> = () => {
  const { ticker } = useParams();
  const [type, setType] = useState("CASH");
  const navigate = useNavigate();
  const [amount, setAmount] = useState({ value: 0, error: false });
  const [reinvest, setReinvest] = useState(false);
  const date = new Date().toISOString().substring(0, 10);
  const [distributionDate, setDistributionDate] = useState({
    value: date,
    error: false,
  });
  const [referenceDate, setReferenceDate] = useState({
    value: date,
    error: false,
  });
  const [nbReinvestedUnits, setNbReinvestedUnits] = useState({
    value: 0,
    error: false,
  });
  const { data, isLoading } = useCustom({
    url: `total-investment/${ticker}`,
    method: "get",
    config: {
      filters: [
        {
          field: 'date',
          operator: "eq",
          value: referenceDate.value,
        },
      ],
    },

  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (type !== "CASH") {
      setReinvest(false);
    }
  }, [type]);

  return (
    <Show
      goBack={
        <Button onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </Button>
      }
      title={
        <Typography variant="h5" color={primary}>
          Add dividend for investment {ticker}{" "}
        </Typography>
      }
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ bgcolor: "background.paper" }}>
          <Divider />

          <Typography py={1}>Please choose dividend type </Typography>
          <Grid item xs={12} sm={10}>
            <ToggleButtonGroup
              fullWidth
              size="small"
              value={type}
              exclusive
              color="primary"
            >
              <ToggleButton
                fullWidth
                value={"UNIT"}
                aria-label="left aligned"
                name="type"
                onClick={() => setType("UNIT")}
              >
                <SvgIcon sx={{ mr: 2 }}>
                  <TrendingUpIcon />
                </SvgIcon>
                UNIT
              </ToggleButton>
              <ToggleButton
                fullWidth
                value={"CASH"}
                aria-label="centered"
                name="type"
                onClick={() => setType("CASH")}
              >
                <SvgIcon sx={{ mr: 2 }}>
                  <AttachMoneyIcon />
                </SvgIcon>
                CASH
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Typography pt={1}>
            Please add the <b>{type} </b>amount
          </Typography>
          <TextField
            value={amount.value || 0}
            error={amount.error}
            helperText={amount.error && "The amount is required!"}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: type === "CASH" && (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            onChange={(e) =>
              setAmount({ value: parseFloat(e.target.value), error: false })
            }
            label={type === "CASH" ? "Amount" : "Number of units"}
            name="amount"
          />
          <Typography py={1}>
            Do you want to <b>reinvest</b> the{" "}
            <b>{moneyFormat(amount.value)}</b>
          </Typography>
          <Grid item xs={12} sm={10}>
            <ToggleButtonGroup
              fullWidth
              size="small"
              value={reinvest ? "reinvest" : "distribute"}
              exclusive
              color="primary"
            >
              <ToggleButton
                fullWidth
                value={"reinvest"}
                aria-label="left aligned"
                name="reinvest"
                disabled={type === "UNIT"}
                onClick={() => setReinvest(true)}
              >
                <SvgIcon sx={{ mr: 2 }}>
                  <TrendingUpIcon />
                </SvgIcon>
                Yes, Reinvest them
              </ToggleButton>
              <ToggleButton
                fullWidth
                value={"distribute"}
                aria-label="centered"
                name="reinvest"
                onClick={() => setReinvest(false)}
              >
                <SvgIcon sx={{ mr: 2 }}>
                  <AttachMoneyIcon />
                </SvgIcon>
                No, Distribute them
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {reinvest && (
            <>
              <Typography pt={2}>
                Please add the number of reinvested units
              </Typography>
              <Grid item xs={12}>
                <TextField
                  id="nbReinvestedUnits"
                  name="nbReinvestedUnits"
                  label="Number of units"
                  error={nbReinvestedUnits.error}
                  helperText={
                    nbReinvestedUnits.error &&
                    "The number of units is required!"
                  }
                  value={nbReinvestedUnits.value || 0}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  onChange={(e) => {
                    setNbReinvestedUnits({
                      value: parseFloat(e.target.value),
                      error: false,
                    });
                  }}
                />
              </Grid>
            </>
          )}
          <Typography pt={2}>
            Please add the ex dividend  date
          </Typography>
          <Grid item xs={12}>
            <TextField
              id="referenceDate"
              error={referenceDate.error}
              helperText={
                referenceDate.error &&
                "The date is required, and can not be negative value!"
              }
              defaultValue={referenceDate.value}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="date"
              label="Date"
              name="referenceDate"
              onChange={(event) => {
                setReferenceDate({
                  value: event.target.value,
                  error: false,
                });
              }}
            />
          </Grid>
          <Typography pt={2}>
            Please add the dividend payment date
          </Typography>
          <Grid item xs={12}>
            <TextField
              id="distributionDate"
              error={distributionDate.error}
              helperText={
                distributionDate.error &&
                "The date is required, and can not be negative value!"
              }
              defaultValue={distributionDate.value}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="date"
              label="Date"
              name="distributionDate"
              onChange={(event) => {
                setDistributionDate({
                  value: event.target.value,
                  error: false,
                });
              }}
            />
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "flex-end",
              marginTop: 3,
            }}
          >
            <Button
              variant="contained"
              disabled={
                amount.value === 0 ||
                (reinvest && nbReinvestedUnits.value <= 0) ||
                isLoading ||
                open
              }
              startIcon={
                isLoading && <CircularProgress size={20} color="inherit" />
              }
              onClick={(e) => {
                if (amount.value === 0 || !amount.value) {
                  setAmount({ ...amount, error: true });
                }
                if (!distributionDate.value) {
                  setDistributionDate({ ...distributionDate, error: true });
                }
                if (reinvest && nbReinvestedUnits.value <= 0) {
                  setNbReinvestedUnits({ ...nbReinvestedUnits, error: true });
                }
                if (
                  !amount.error &&
                  !nbReinvestedUnits.error &&
                  !distributionDate.error
                ) {
                  setOpen(true);
                }
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      )}
      <DividendConfirmationModal
        open={open}
        setOpen={setOpen}
        ticker={ticker!}
        reinvest={reinvest}
        amount={amount.value}
        type={type}
        nbReinvestedUnits={
          type === "CASH" && reinvest ? nbReinvestedUnits.value : 0
        }
        data={data?.data.funds}
        distributionDate={distributionDate.value}
        referenceDate={referenceDate.value}
      />
    </Show>
  );
};
