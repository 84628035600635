export enum INVESTMENT_RISK_TYPE_ENUM {
  EQUITY = "EQUITY",
  FIXED_INCOME = "FIXED_INCOME",
  OTHER = "OTHER",
}
export enum INVESTMENT_FORMAT_ENUM {
  SHARES = "SHARES",
  ETF = "ETF",
  BOND = "BOND",
  MUTUAL_FUNDS = "MUTUAL_FUNDS", // exchange traded funds
}
export interface NetaTotalShares {
  totalAmount: number;
  nbFund: number;
  funds: { idFund: number; name: string; units: number; ratio: number }[];
}
export interface IInvestment {
  ticker: string;
  name: string;
  description: string;
  riskType: INVESTMENT_RISK_TYPE_ENUM;
  format: INVESTMENT_FORMAT_ENUM;
  isActive: boolean;
  isAFP: boolean;
  isLiquid: boolean;
  isListed: boolean;
  marketPrice: number;
  dailyChange: number;
  investmentMarketPriceHistory?: InvestmentMarketPriceItem[] | undefined;
  priceLastFetchedAt: Date;
  totalInvestments?: NetaTotalShares | undefined;
}

export interface InvestmentMarketPriceItem {
  marketPriceId: number;
  marketPrice: number;
  createdAt?: Date;
  updatedAt?: Date;
  referenceDate: Date;
  investment: IInvestment;
  locked: boolean;
  netaTotalApprovedUnits?: number;
}
