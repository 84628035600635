/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useCreate } from "@refinedev/core";
import { useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useMemo, useState } from "react";

interface ModalProps {
  visible: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  grantvestmentId: number;
  refetch: any;
}
export const GrantvestmentUpdatesVisibilityModal: React.FC<ModalProps> = ({
  visible,
  close,
  grantvestmentId,
  refetch,
}) => {
  const { mutate, isLoading } = useCreate();
  const { autocompleteProps: users } = useAutocomplete({
    resource: "Users",
  });
  const userOptions = useMemo(
    () =>
      users.options?.map((user, index) => {
        return {
          sub: user.sub,
          label: `${index + 1}- ${user.firstName} ${user.lastName}`,
        };
      }),
    [users.options]
  );
  const [selectedUserSub, setSelectedUserSub] = useState("");
  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <Dialog open={visible} onClose={() => close(false)} fullWidth>
      <DialogTitle color="primary">
        Allow user to view the GrantVestment updates
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minHeight: 300 }}>
        <Typography variant="body1" color="primary" fontWeight="bold" pb={2}>
          Please select a user
        </Typography>
        {!users.loading && userOptions ? (
          <Autocomplete
            disablePortal
            id="user-select"
            options={userOptions}
            onChange={(_, value) => {
              setSelectedUserSub(value?.sub);
            }}
            isOptionEqualToValue={(option, value) => option.sub === value.sub}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Please chose or type the user name"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                {...register("user", {
                  required: "Please Choose a user",
                })}
                error={!!(errors as any)?.user}
                helperText={(errors as any)?.user?.message}
              />
            )}
          />
        ) : (
          <CircularProgress size={20} color="inherit" />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            disabled={isLoading || users.loading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              mutate(
                {
                  resource: `grant-vestments/${grantvestmentId}/authorize`,
                  values: {
                    userSub: selectedUserSub,
                  },
                },
                {
                  onSuccess: () => {
                    refetch();
                    close(false);
                  },
                }
              );
            }}
          >
            Confirm
          </Button>
          <Button variant="outlined" onClick={() => close(false)}>
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
