import {
  IResourceComponentsProps,
  useCustom,
  useUpdate,
} from "@refinedev/core";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import { useState } from "react";
import { IInvestment } from "interfaces/investment";
import { Show } from "@refinedev/mui";
import { UpdateInvestmentCard } from "components/card/UpdateInvestmentCard";
import { getLast7Dates } from "utils/date-format";
import { useNavigate } from "react-router-dom";

export const BatchUpdate: React.FC<IResourceComponentsProps> = () => {
  const last7Dates = getLast7Dates();
  const navigate = useNavigate();
  const [hideZeroAmount, setHideZeroAmount] = useState(false);
  const {
    data: investments,
    isSuccess: isGetInvestmentSuccess,
    refetch,
  } = useCustom({
    url: `investments-with-history`,
    method: "get",
  });
  const { data, refetch: reload } = useCustom({
    url: `investments-with-history/locked`,
    method: "get",
  });
  const { mutate, isLoading } = useUpdate();
  const [updatedInvestment, setUpdatedInvestment] = useState<
    {
      ticker: string;
      id: number;
      marketPrice: number;
      dailyChange: number;
      date: Date;
    }[]
  >([]);

  const updateInvestment = (
    ticker: string,
    id: number,
    marketPrice: number,
    dailyChange: number,
    date: Date
  ) => {
    setUpdatedInvestment((prev) => {
      const newData = {
        ticker,
        id,
        marketPrice,
        dailyChange,
        date,
      };
      let updated = prev;
      const index = updated.findIndex((item) => item.id === id);
      if (index === -1) {
        updated.push(newData);
      } else {
        updated[index] = newData;
      }
      return updated;
    });
  };
  const submit = () => {
    mutate(
      {
        resource: "investments-batch-update",
        values: { input: updatedInvestment },
        id: "",
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  const lock = (date: string) => {
    mutate(
      {
        resource: "investments-history-lock",
        values: { date: date },
        id: "",
      },
      {
        onSuccess: () => {
          refetch();
          reload();
        },
      }
    );
  };
  const canLockDay = (date: string) => {
    if (data && data.data) {
      const lastDay = new Date(data?.data as any);
      lastDay.setDate(lastDay.getDate() + 1);
      return date === lastDay.toISOString().split("T")[0];
    } else return false;
  };

  return (
    <Show title={""}>
      <Box sx={{ marginY: 4, width: "fit-content" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideZeroAmount}
              onChange={(e) => setHideZeroAmount(e.target.checked)}
              color="primary"
            />
          }
          label="Hide investments with zero units"
        />
        {isGetInvestmentSuccess && data && (
          <>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                maxWidth: 1150,
                overflowX: "auto",
                border: "1px solid",
              }}
            >
              <Table size="small" sx={{ width: "inherit", display: "block" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                        minWidth: 100,
                        border: "1px solid rgba(224, 224, 224, 1);",
                      }}
                    >
                      Ticker
                    </TableCell>
                    {last7Dates.map((date) => (
                      <TableCell
                        sx={{
                          minWidth: 150,
                          border: "1px solid rgba(224, 224, 224, 1);",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {date}
                          <IconButton
                            onClick={() => lock(date)}
                            edge="end"
                            color="primary"
                            disabled={!canLockDay(date)}
                          >
                            <LockIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ width: "inherit", display: "block" }}>
                  {investments.data.data &&
                    investments.data.data.length > 0 &&
                    investments.data.data
                      .filter(
                        (investment: IInvestment) =>
                          !hideZeroAmount ||
                          (investment.investmentMarketPriceHistory &&
                            investment.investmentMarketPriceHistory[0]
                              .netaTotalApprovedUnits !== 0)
                      )
                      .map((investment: IInvestment, index: number) => (
                        <UpdateInvestmentCard
                          investment={investment}
                          updateInvestment={updateInvestment}
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ my: 4, display: "flex", justifyContent: "flex-end" }}>
              <Button
                sx={{ mr: 2 }}
                startIcon={
                  isLoading && <CircularProgress size={20} color="inherit" />
                }
                disabled={isLoading}
                variant="contained"
                onClick={() => {
                  submit();
                }}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Show>
  );
};
