import { Divider } from "@aws-amplify/ui-react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useUpdate } from "@refinedev/core";
import { useState } from "react";
interface ModalProps {
  open: boolean;
  refetch: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
}
export const AddFundReference: React.FC<ModalProps> = ({
  setOpen,
  refetch,
  open,
  id,
}) => {
  const { mutate, isLoading } = useUpdate();

  const [reference, setReference] = useState("");
  const [error, setError] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setReference("");
          setError(false);
        }}
        fullWidth
      >
        <DialogTitle>Add fund reference</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            error={error}
            helperText={error && "Reference is required!"}
            margin="normal"
            fullWidth
            style={{ marginTop: 10 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setReference(e.target.value);
              e.target.value === "" ? setError(true) : setError(false);
            }}
            type="text"
            name="email"
            label="Reference"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading || reference === ""}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (!reference) {
                setError(true);
              } else {
                mutate(
                  {
                    resource: `funds`,
                    values: {
                      reference: reference,
                    },
                    id: id,
                    errorNotification: (error) => {
                      console.log(error);
                      return {
                        message: error?.message?.includes("duplicate key value")
                          ? "Reference already exist"
                          : error?.message?.includes("unique_reference")
                          ? "The following reference is used!"
                          : "Oops! something went wrong, please try later.",
                        type: "error",
                      };
                    },
                  },
                  {
                    onSuccess: () => {
                      setReference("");
                      setError(false);
                      setOpen(false);
                      refetch();
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              setReference("");
              setError(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
